<template>
  <ClinicDeatil backRoute="DoctorClinic"></ClinicDeatil>
</template>

<script>
import ClinicDeatil from "@/views/components/clinics/ClinicDetailPage.vue";

export default {
  components: {
    ClinicDeatil
  },
  data: () => ({})
};
</script>